<template>
  <div>
    <b-row class="mt-2 mb-3">
      <b-col
          lg="12"
      >
       <h2 style="text-align: center">{{title}}</h2>
      </b-col>
    </b-row>
    <b-form @submit.prevent="createHistorySend(history)">
      <b-row>
        <b-col cols="12" class="mb-1">
          <app-collapse   accordion type="margin">

            <app-collapse-item  title="IDENTIFICACIÓN" is-visible>
              <b-row>

                <b-col cols="6">
                  <b-form-group
                      label="Estatura"
                  >
                    <b-form-input
                        v-model="history.height"
                        placeholder="Estatura"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                      label="Peso"
                  >
                    <b-form-input
                        v-model="history.weight"
                        placeholder="Peso"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                      label="IMC"
                  >
                    <b-form-input
                        v-model="history.imc"
                        placeholder="IMC"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                      label="Talla"
                  >
                    <b-form-input
                        v-model="history.size"
                        placeholder="Talla"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                      label="Estado Civil"
                  >
                    <b-form-select
                        v-model="history.civil_status"
                        :options="status_civil"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>

            <app-collapse-item title="ANTECEDENTES HEREDO FAMILIARES:" >
              <b-row>

              <b-col cols="3" class="mt-2">
                <div class="demo-inline-spacing">
                  <h4>Hipertensión arterial:</h4>
                  <b-form-radio
                      v-model="history.blood_pressure"
                      :value="true"
                  >
                    Si
                  </b-form-radio>
                  <b-form-radio
                      v-model="history.blood_pressure"
                      :value="false"
                  >
                    No
                  </b-form-radio>
                </div>
              </b-col>
              <b-col cols="3" class="mt-2">
                <div class="demo-inline-spacing">
                  <h4>Cardiopatía:</h4>
                  <b-form-radio
                      v-model="history.heart_disease"
                      :value="true"
                  >
                    Si
                  </b-form-radio>
                  <b-form-radio
                      v-model="history.heart_disease"
                      :value="false"
                  >
                    No
                  </b-form-radio>
                </div>
              </b-col>
              <b-col cols="3" class="mt-2">
                <div class="demo-inline-spacing">
                  <h4>Oncologicos:</h4>
                  <b-form-radio
                      v-model="history.oncology"
                      :value="true"
                  >
                    Si
                  </b-form-radio>
                  <b-form-radio
                      v-model="history.oncology"
                      :value="false"
                  >
                    No
                  </b-form-radio>
                </div>
              </b-col>
              <b-col cols="3" class="mt-2">
                <div class="demo-inline-spacing">
                  <h4>Diabetes:</h4>
                  <b-form-radio
                      v-model="history.diabetes"
                      :value="true"
                  >
                    Si
                  </b-form-radio>
                  <b-form-radio
                      v-model="history.diabetes"
                      :value="false"
                  >
                    No
                  </b-form-radio>

                </div>
              </b-col>
              </b-row>
            </app-collapse-item>

            <app-collapse-item title="ANTECEDENTES PERSONALES NO PATOLÓGICOS:">
              <b-row>
              <b-col cols="3">
                <b-row>
                  <b-col cols="7">

                    <h4>Tabaquismo:</h4>
                    <b-form-radio
                        v-model="history.tobacco"
                        :value="true"
                    >
                      Si
                    </b-form-radio>
                    <b-form-radio
                        v-model="history.tobacco"
                        :value="false"
                    >
                      No
                    </b-form-radio>

                  </b-col>
                  <b-col cols="5">
                    <b-form-input style="margin-top: 10px"
                                  v-model="history.tobacco_qty"
                                  placeholder="Cantidad Tabaco"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <b-col cols="7">
                    <h4>Alcoholismo:</h4>
                    <b-form-radio
                        v-model="history.alcoholism"
                        :value="true"
                    >
                      Si
                    </b-form-radio>
                    <b-form-radio
                        v-model="history.alcoholism"
                        :value="false"
                    >
                      No
                    </b-form-radio>
                  </b-col>
                  <b-col cols="5">
                    <b-form-input style="margin-top: 10px"
                                  v-model="history.alcoholism_qty"
                                  placeholder="Frecuencia"
                    />

                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-form-group
                    label="Drogas"
                >
                  <b-form-input
                      v-model="history.drugs"
                      placeholder="Drogas"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                    label="Inmunizaciones"
                >
                  <b-form-input
                      v-model="history.immunizations"
                      placeholder="Inmunizaciones"
                  />
                </b-form-group>
              </b-col>
              </b-row>
            </app-collapse-item>

            <app-collapse-item title="DESCRIPCIÓN PADECIMIENTO ACTUAL (MOTIVO DE CONSULTA)">
              <b-row>
              <b-col cols="5">
                <b-form-group
                    label="Tipo de cancer"
                >
                  <b-form-input
                      v-model="history.type_cancer"
                      placeholder="Tipo de cancer"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <VoiceInput label="Otros sintomas" placeholder="Otros sintomas" rows="3" id="textarea-default" v-model="history.other_syntoms" />
              </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-col>

        <b-col cols="12" style="margin-top: 10px">
          <b-button
              style="width: 100%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Guardar
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BButton,
  BAvatar,
  BFormFile, BForm, BFormSelect, BFormRadio,
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions} from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VoiceInput from "@/components/VoiceInput.vue";

export default {
  name: "HistoricClinic",
  components: {
    VoiceInput,
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile, flatPickr, BFormSelect, BFormRadio, ToastificationContent, AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  props: {
    title: String,
    data_patient: Object
  },
  watch:{
    async data_patient(){
      await this.getHistory()
    }
  },
  data() {
    return {
      create_history: false,
      status_civil: [
        {value: '', text: 'Seleccione una opción'},
        {value: 'Soltero/a', text: 'Soltero/a'},
        {value: 'Casado/a', text: 'Casado/a'},
        {value: 'Divorciado/a', text: 'Divorciado/a'},
        {value: 'Viudo/a', text: 'Viudo/a'},
      ],
      totalSmoke:0,
      history: {
        id: '',
        patient_id: 0,
        height: '',
        tobacco_years: '',
        tobacco_day: '',
        imc: '',
        size: '',
        civil_status: '',
        blood_pressure: '',
        heart_disease: '',
        weight: '',
        oncology: '',
        diabetes: '',
        other_syntoms: '',
        type_cancer: '',
        tobacco: '',
        tobacco_qty: '',
        alcoholism: '',
        alcoholism_qty: '',
        immunizations: '',
        drugs: '',
      }
    }
  },

  async created() {

  },
  methods: {
    ...mapActions('clinic_patient', ['findHistory', 'createHistory']),
    async getHistory() {
      const response = await this.findHistory(this.data_patient.id);
      if (response) {

        this.history = response;
        this.history.id = response.id;
        this.create_history = true
      } else {
        this.create_history = false
      }

    },
    async createHistorySend() {
      this.history.patient_id = this.data_patient.id
      const response = await this.createHistory(this.history);
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Historial Creado Correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getHistory();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al crear Historial',
            icon: 'error',
            variant: 'warning',
          },
        });
      }
    }
  }

}
</script>

<style scoped>

</style>
